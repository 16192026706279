// src/components/Features.js
import React from 'react';

const Features = () => {
  return (
    <section className="py-20" style={{ backgroundColor: '#008080' }}>
      <div className="container mx-auto flex flex-col md:flex-row items-center px-6">
        {/* Left Side - Features Content */}
        <div className="w-full md:w-1/2 text-white text-center md:text-left mb-8 md:mb-0 md:mr-8">
          {/* Added md:mr-8 for spacing */}
          <h3
            className="text-xl font-semibold mb-2"
            style={{ fontFamily: '"halcom", Sans-serif' }}
          >
            Take A Look
          </h3>
          <h2
            className="text-5xl font-bold mb-6"
            style={{
              fontFamily: '"ice-cream-standard", Sans-serif', // Ensure correct font usage
              fontWeight: '800',
            }}
          >
            Our Family.<br /> Your Community.
          </h2>

          {/* How It Works Section */}
          <div className="mb-8">
            <h4
              className="text-lg font-semibold mb-4"
              style={{ 
                fontFamily: '"halcom", Sans-serif', // Consistent Halcom font style for subtitle
                fontWeight: '600', 
              }}
            >
              HOW IT WORKS:
            </h4>
            <div className="flex flex-col md:flex-row gap-6">
              {/* Feature 1 */}
              <div className="flex-1">
                <h5
                  className="text-white font-bold text-lg mb-2"
                  style={{
                    fontFamily: '"ice-cream-standard", Sans-serif', // Number font
                    fontWeight: '400', // Bold for emphasis
                  }}
                >
                  01.
                </h5>
                <h6
                  className="text-white font-semibold text-lg mb-2"
                  style={{
                    fontFamily: '"ice-cream-standard", Sans-serif', // Title font
                    fontWeight: '400', // Bold for emphasis
                  }}
                >
                  Take Survey
                </h6>
                <p
                  className="text-white text-base mb-4"
                  style={{
                    fontFamily: '"halcom", Sans-serif', // Paragraph font
                    fontWeight: '400', // Normal weight for readability
                    lineHeight: '1.6', // Improved readability with increased line height
                  }}
                >
                  We want this platform to be totally customized to give you the best match. Take the survey to help us personalize it for you.
                </p>
                <a
                  href="https://forms.gle/zSQA9Te3QtWMCDBUA"
                  className="inline-block bg-[#FED700] text-teal-900 font-semibold px-6 py-2 rounded hover:bg-yellow-400"
                  style={{
                    fontFamily: '"icecream", Sans-serif',
                    fontSize: '17px',
                    textDecoration: 'none',
                  }}
                >
                  Start Survey
                </a>
              </div>

              {/* Feature 2 */}
              <div className="flex-1">
                <h5
                  className="text-white font-bold text-lg mb-2"
                  style={{
                    fontFamily: '"ice-cream-standard", Sans-serif', // Number font
                    fontWeight: '400', // Bold for emphasis
                  }}
                >
                  02.
                </h5>
                <h6
                  className="text-white font-semibold text-lg mb-2"
                  style={{
                    fontFamily: '"ice-cream-standard", Sans-serif', // Title font
                    fontWeight: '400', // Bold for emphasis
                  }}
                >
                  Create Profile
                </h6>
                <p
                  className="text-white text-base"
                  style={{
                    fontFamily: '"halcom", Sans-serif', // Paragraph font
                    fontWeight: '400', // Normal weight for readability
                    lineHeight: '1.6', // Improved readability with increased line height
                  }}
                >
                  Craft your KidMatch profile. Those filters are your friends! You can be as particular as you want to find the top league parents to add to your tribe.
                </p>
              </div>

              {/* Feature 3 */}
              <div className="flex-1">
                <h5
                  className="text-white font-bold text-lg mb-2"
                  style={{
                    fontFamily: '"ice-cream-standard", Sans-serif', // Number font
                    fontWeight: '600', // Bold for emphasis
                  }}
                >
                  03.
                </h5>
                <h6
                  className="text-white font-semibold text-lg mb-2"
                  style={{
                    fontFamily: '"ice-cream-standard", Sans-serif', // Title font
                    fontWeight: '600', // Bold for emphasis
                  }}
                >
                  Match & Meet
                </h6>
                <p
                  className="text-white text-base"
                  style={{
                    fontFamily: '"halcom", Sans-serif', // Paragraph font
                    fontWeight: '400', // Normal weight for readability
                    lineHeight: '1.6', // Improved readability with increased line height
                  }}
                >
                  We know parenting can take a lot out of you. That’s why we’ve made this platform for you to easily find like-minded parents and kids to make lasting relationships with.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Right Side - Image */}
        <div className="w-full md:w-1/2 flex justify-center md:ml-8">
          {/* Added md:ml-8 for spacing */}
          <img
            src={`${process.env.PUBLIC_URL}/images/feature-image.png`}  // Update with the correct path
            alt="You've Been Matched"
            className="w-full max-w-lg md:max-w-2xl lg:max-w-3xl"  // Increased image size for larger screens
          />
        </div>
      </div>
    </section>
  );
};

export default Features;
