// src/components/HowWeOperateSection.js
import React from 'react';

const HowWeOperateSection = () => {
  return (
    <section className="bg-[#C7B2D6] py-36">
      <div className="container mx-auto px-6 max-w-[1080px]">
        {/* Header with ice-cream-standard font and weight 600 */}
        <h2 
          className="text-5xl mb-2 text-center leading-tight" 
          style={{ 
            fontFamily: '"ice-cream-standard", sans-serif',  // Adobe font
            fontWeight: '600',  // Weight 600 for bold
            color: '#310068'  // Header color
          }}
        >
          How We Operate
        </h2>

        {/* White Divider under the header due to background contrast */}
        <div className="border-t-4 border-white w-24 mx-auto mb-10"></div>

        {/* Content Paragraph */}
        <div className="px-6 text-center">
          <p 
            className="text-gray-700 text-lg"
            style={{
              fontFamily: '"halcom", sans-serif',  // Consistent font family for the paragraph
              fontWeight: '400',  // Normal weight for readability
              lineHeight: '1.6',  // Improved readability with increased line height
            }}
          >
            KidMatch operates with the understanding that parenting is more than just a role—it’s a journey filled with joys, challenges, and the need for community. Our platform automates the playdate coordination process, removing the manual steps and back-and-forth communication that often make organizing playdates stressful. We make it easy for you to set up playdates, connect with new families, and stay informed about events in your area. Our approach is rooted in empathy and efficiency, ensuring that both parents and children have positive, enriching experiences.
          </p>
        </div>
      </div>
    </section>
  );
};

export default HowWeOperateSection;
