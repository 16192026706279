import React from 'react';

const LearnMoreSection = () => {
  return (
    <section className="bg-white py-16">
      <div className="container mx-auto px-6 max-w-[1080px] text-center">
        {/* Subtitle */}
        <h4
          className="text-lg font-semibold text-[#553293] mb-4"
          style={{
            fontFamily: '"halcom", Sans-serif', 
            fontWeight: '600', 
          }}
        >
          Ready, Set, Play.
        </h4>

        {/* Main Title */}
        <h2
          className="text-5xl font-semibold text-[#310068] mb-6 leading-tight"
          style={{
            fontFamily: '"ice-cream-standard", Sans-serif', 
            fontWeight: '600', 
          }}
        >
          Learn More
        </h2>

        {/* Image */}
        <div className="flex justify-center mb-8">
          <img
            src={`${process.env.PUBLIC_URL}/images/learnmore.png`} // Update with the correct path to your image
            alt="Learn More"
            className="max-w-lg rounded-lg"
          />
        </div>

        {/* Text */}
        <p
          className="text-gray-700 text-lg mb-10"
          style={{
            fontFamily: '"halcom", Sans-serif', 
            fontWeight: '400',
            lineHeight: '1.6', 
          }}
        >
          KidMatch helps parents with kids ages 0-12 who want to find other parents to be friends. By utilizing our mobile application, connecting parents with specific needs, and inviting moms and dads unlike other solutions that make parents spend too much time finding other parent friends. Our mobile application is launching this Fall! Our survey will take 2 minutes or less.
        </p>

        {/* Take Survey Button */}
        <a
          href="https://forms.gle/zSQA9Te3QtWMCDBUA"
          className="inline-block text-teal-900 bg-[#FED700] font-semibold w-full py-3 hover:bg-[#C7B2D6] transition-colors duration-300"
          style={{
            fontFamily: '"ice-cream-standard", Sans-serif',
            fontSize: '17px',
            textDecoration: 'none',
            fontWeight: '400', 
            borderRadius: '3px',
          }}
        >
          Take Survey
        </a>
      </div>
    </section>
  );
};

export default LearnMoreSection;
