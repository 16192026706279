// src/components/WhoWeServeSection.js
import React from 'react';

const WhoWeServeSection = () => {
  return (
    <section className="bg-white py-28">
      <div className="container mx-auto px-6 max-w-[1080px] border border-[#310068] rounded-lg py-12">
        {/* Header with ice-cream-standard font and weight 600 */}
        <h2 
          className="text-5xl mb-2 text-center leading-tight" 
          style={{ 
            fontFamily: '"ice-cream-standard", sans-serif',  // Adobe font
            fontWeight: '600',  // Weight 600 for bold
            color: '#310068'  // Header color
          }}
        >
          Who We Serve
        </h2>

        {/* Divider under the header with specified color */}
        <div className="border-t-4 border-[#C7B2D6] w-24 mx-auto mb-10"></div>
        
        {/* Content Paragraph */}
        <div className="px-6 text-center">
          <p 
            className="text-gray-700 text-lg"
            style={{
              fontFamily: '"halcom", sans-serif',  // Consistent font family for the paragraph
              fontWeight: '400',  // Normal weight for readability
              lineHeight: '1.6',  // Improved readability with increased line height
            }}
          >
            We serve parents who want to build a community for themselves and their children. We understand that parenting can be lonely, and with our platform we want to make that easier. We serve BIPOC parents, fathers, mothers, and nonbinary parents. Whether you’re a stay-at-home parent, a working professional, or managing a blended family, KidMatch is designed to make your life easier. Our platform connects parents who share similar parenting stages and interests, enabling you to arrange playdates, discover events, and create lasting social circles for your children.
          </p>
        </div>
      </div>
    </section>
  );
};

export default WhoWeServeSection;
