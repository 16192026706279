import React from 'react';
import Header from '../components/Header';  // Ensure the path is correct
import Footer from '../components/Footer';  // Ensure the path is correct
import LearnMoreSection from '../components/LearnMoreSection'; // Import LearnMoreSection component

const LearnMore = () => {
  return (
    <>
      {/* Header */}
      <Header />

      {/* Learn More Section */}
      <LearnMoreSection />

      {/* Footer */}
      <Footer />
    </>
  );
};

export default LearnMore;
