// src/components/Hero.js
import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const Hero = () => {
  return (
    <section
      className="py-20"
      style={{
        backgroundColor: 'transparent',
        backgroundImage:
          'radial-gradient(at top left, #C7B2D6 38%, #FFFFFF 0%)', // Updated Background gradient with real colors
      }}
    >
      <div className="container mx-auto flex flex-col md:flex-row items-center px-6">
        {/* Left Side - Images */}
        <div className="w-full md:w-1/2 mb-8 md:mb-0 flex justify-center">
          <img
            src={`${process.env.PUBLIC_URL}/images/home-image.png`}  // Update with the correct path
            alt="Connect With Other Families"
            className="w-full max-w-lg md:max-w-xl"  // Larger image size
          />
        </div>

        {/* Right Side - Text Content */}
        <div className="w-full md:w-1/2 text-center md:text-left px-4">
          {/* Subtitle */}
          <h4
            className="text-[#3a3a3a] mb-2"
            style={{
              fontFamily: '"halcom", Sans-serif', // Use Adobe font 
              fontSize: '18px',
              fontWeight: '600',  // Standard weight
              fontStyle: 'normal',
            }}
          >
            Connecting Parents In Similar Stages
          </h4>

          {/* Main Title */}
          <h2
            className="text-black mb-4 font-extrabold"
            style={{
              fontFamily: '"ice-cream-standard", Sans-serif',  // Ensure the ice-cream-standard font is applied
              lineHeight: '1.2',
            }}
          >
            <span className="text-3xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-[70px]">Find Your Parent Tribe.</span>
          </h2>

          {/* Learn More Button */}
          <Link
            to="/learn-more" // Updated to Link component with 'to' prop
            className="inline-block text-white bg-[#2C8572] border-2 border-[#2C8572] w-full md:w-auto py-3 px-6 hover:bg-[#226955] hover:border-[#226955] mb-6"
            style={{
              fontFamily: '"ice-cream-standard", Sans-serif',  // Button font style matches the rest
              fontSize: '16px',  // Set to 16px
              fontWeight: '400',  // Standard weight
              borderRadius: '3px',
              lineHeight: '1.2',  // Smaller line height
              textAlign: 'center',
              textDecoration: 'none', // Ensure there's no default underline
            }}
          >
            Learn More
          </Link>

          {/* Paragraph Text */}
          <p
            className="text-[#333333]"
            style={{
              fontSize: '17px',
              fontFamily: '"halcom", Sans-serif', // Consistent font family for the paragraph
              fontWeight: '400', // Normal weight for readability
              lineHeight: '1.5',
            }}
          >
            Connect with genuine local parents at similar parenting stages on KidMatch where authentic profiles are enriched with real-life crossings, favorite Spots, and personalized insights. By using data-driven insights and detailed personal profiles, we foster genuine connections between parents who share similar interests and values, creating a supportive and engaged community.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Hero;
