// src/components/CTASection.js
import React from 'react';

const CTASection = () => {
  return (
    <section
      className="relative bg-cover bg-center flex justify-center items-center"
      style={{
        backgroundImage: `url('${process.env.PUBLIC_URL}/images/cta-background.png')`, // Update with the correct path to your background image
      }}
    >
      {/* Centered Card */}
      <div className="container mx-auto flex justify-center items-center">
        <div className="bg-[#553293] text-white max-w-xl p-10 rounded-lg shadow-lg text-center">
          {/* Subtitle */}
          <h4
            className="text-lg font-semibold mb-2"
            style={{ 
              fontFamily: '"halcom", Sans-serif',  // Use Halcom font
              fontWeight: '600',
            }}
          >
            Ready, Set, Play.
          </h4>

          {/* Main Title */}
          <h2
            className="text-5xl font-bold mb-4"
            style={{
              fontFamily: '"ice-cream-standard", Sans-serif',  // Use Adobe font for the header
              fontWeight: '800',  // Bold weight
            }}
          >
            Join Your Parent Tribe
          </h2>

          {/* Description */}
          <p
            className="text-base mb-8"
            style={{
              fontFamily: '"halcom", Sans-serif',  // Consistent Halcom font style
              fontWeight: '400',
              lineHeight: '1.6',
            }}
          >
            KidMatch connects parents in similar parenting stages and interests. KidMatch matches parents who have crossed paths or have similar routines to arrange playdates and create social circles for their children. Parents can discover events, set up playdates, and connect with new family matches within our growing community, simplifying the process of building a supportive network for both parents and their kids.
          </p>

          {/* CTA Button */}
          <a
            href="https://forms.gle/zSQA9Te3QtWMCDBUA"
            className="inline-block bg-[#FED700] text-teal-900 font-semibold px-6 py-3 rounded hover:bg-yellow-400"
            style={{
              fontFamily: '"ice-cream-standard", Sans-serif',  // Use Adobe font for the button
              fontSize: '17px',
              fontWeight: '400',  // Normal weight
              textDecoration: 'none',
            }}
          >
            Get Started
          </a>
        </div>
      </div>
    </section>
  );
};

export default CTASection;
