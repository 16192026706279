// src/components/Header.js
import React, { useState } from 'react';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <header className="sticky top-0 z-50">
      {/* Top Section */}
      <div className="bg-[#FED700] py-2">
        <div className="container mx-auto flex justify-end space-x-4 px-6">
          {/* Social Media Icons */}
          <a href="https://www.facebook.com/mykidmatch/" className="text-purple-700 hover:text-purple-900">
            <FaFacebook />
          </a>
          <a href="https://www.instagram.com/mykidmatch/" className="text-purple-700 hover:text-purple-900">
            <FaInstagram />
          </a>
        </div>
      </div>

      {/* Bottom Section */}
      <div className="bg-[#553293] py-4">
        <div className="container mx-auto flex justify-between items-center px-6">
          {/* Logo Links to Home */}
          <Link to="/">
            <img
              src={`${process.env.PUBLIC_URL}/images/logo.png`}
              alt="KidMatch Logo"
              className="w-36" // Adjust width as necessary
            />
          </Link>

          {/* Hamburger Menu for Mobile */}
          <div className="md:hidden">
            <button
              onClick={() => setMenuOpen(!isMenuOpen)}
              className="text-white focus:outline-none"
            >
              {/* Hamburger Icon */}
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            </button>
          </div>

          {/* Navigation Links */}
          <nav className={`hidden md:flex items-center space-x-6`}>
            <Link
              to="/about-us" // Link to About Us Page
              className="text-white"
              style={{
                fontFamily: '"ice-cream-standard", Sans-serif',
                fontWeight: '400',
                fontSize: '15px',
              }}
            >
              About Us
            </Link>
            <Link
              to="/learn-more" // Link to Learn More Page
              className="text-white border-2 border-[#E3B221] hover:bg-[#E3B221]"
              style={{
                fontFamily: '"ice-cream-standard", Sans-serif',
                fontSize: '17px',
                fontWeight: '400',
                borderRadius: '3px',
                padding: '12px 24px',
                textDecoration: 'none', // Ensure there's no default underline
              }}
            >
              Learn More
            </Link>
          </nav>
        </div>

        {/* Mobile Menu Dropdown */}
        {isMenuOpen && (
          <div className="bg-[#553293] md:hidden">
            <nav className="flex flex-col items-center space-y-4 py-4">
              <Link
                to="/about-us" // Link to About Us Page
                className="text-white"
                style={{
                  fontFamily: '"ice-cream-standard", Sans-serif',
                  fontWeight: '400',
                  fontSize: '15px',
                }}
              >
                About Us
              </Link>
              <Link
                to="/learn-more" // Link to Learn More Page
                className="text-white border-2 border-[#E3B221] hover:bg-[#E3B221]"
                style={{
                  fontFamily: '"ice-cream-standard", Sans-serif',
                  fontSize: '17px',
                  fontWeight: '400',
                  borderRadius: '3px',
                  padding: '12px 24px',
                  textDecoration: 'none', // Ensure there's no default underline
                }}
              >
                Learn More
              </Link>
            </nav>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
