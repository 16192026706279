// src/pages/AboutUs.js
import React from 'react';
import Header from '../components/Header';  // Adjust import path as necessary
import Footer from '../components/Footer';  // Adjust import path as necessary
import AboutUsSection from '../components/AboutUsSection';
import WhoWeServeSection from '../components/WhoWeServeSection';
import HowWeOperateSection from '../components/HowWeOperateSection';
import MeetTheFoundersSection from '../components/MeetTheFoundersSection';

const AboutUs = () => {
  return (
    <>
      {/* Header */}
      <Header />

      {/* Sections */}
      <AboutUsSection />
      <WhoWeServeSection />
      <HowWeOperateSection />
      <MeetTheFoundersSection />

      {/* Footer */}
      <Footer />
    </>
  );
};

export default AboutUs;
