// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import AboutUs from './pages/AboutUs'; // Import your About Us page
import LearnMore from './pages/LearnMore';  // Import LearnMore page
import './styles/globals.css'; // Ensure Tailwind CSS is loaded

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} /> {/* Home Page Route */}
        <Route path="/about-us" element={<AboutUs />} /> {/* About Us Page Route */}
        <Route path="/learn-more" element={<LearnMore />} /> {/* Add new route */}
      </Routes>
    </Router>
  );
}

export default App;
